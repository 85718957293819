var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "app-form",
        {
          attrs: {
            id: "calendar-type-form",
            submitAction: _vm.edit,
            submitSuccessAction: _vm.editSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasMultipleOffices,
                  expression: "hasMultipleOffices"
                }
              ],
              staticClass: "form-group"
            },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "officeId",
                  placeholder: _vm.$t("offices.office"),
                  dataSource: _vm.offices,
                  fields: { text: "name", value: "id" },
                  enabled: false
                },
                model: {
                  value: _vm.calendarType.officeId,
                  callback: function($$v) {
                    _vm.$set(_vm.calendarType, "officeId", $$v)
                  },
                  expression: "calendarType.officeId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "name", placeholder: _vm.$t("shared.name") },
                model: {
                  value: _vm.calendarType.name,
                  callback: function($$v) {
                    _vm.$set(_vm.calendarType, "name", $$v)
                  },
                  expression: "calendarType.name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "description",
                  placeholder: _vm.$t("shared.description")
                },
                model: {
                  value: _vm.calendarType.description,
                  callback: function($$v) {
                    _vm.$set(_vm.calendarType, "description", $$v)
                  },
                  expression: "calendarType.description"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("h4", { staticStyle: { margin: "12px 0 6px" } }, [
                _vm._v(_vm._s(_vm.$t("employees.employees")))
              ]),
              _c(
                "ejs-grid",
                {
                  ref: "calendarTypeEmployeesGrid",
                  attrs: {
                    allowSorting: true,
                    dataSource: _vm.calendarType.employees,
                    editSettings: _vm.employeesGrid.editSettings,
                    sortSettings: _vm.employeesGrid.sortOptions,
                    toolbar: _vm.employeesGrid.toolbar,
                    actionBegin: _vm.employeesGridActionBegin,
                    actionComplete: _vm.employeesGridActionComplete,
                    rowDataBound: _vm.onEmployeesGridRowDataBound
                  }
                },
                [
                  _c(
                    "e-columns",
                    [
                      _c("e-column", {
                        attrs: {
                          field: "employeeId",
                          headerText: _vm.$t("shared.id"),
                          width: "0",
                          validationRules:
                            _vm.employeesGrid.validationOptions.rules
                              .employeeId,
                          type: "number"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "employeeFullName",
                          headerText: _vm.$t("employees.employee"),
                          width: "200"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "visibility",
                          width: "0",
                          validationRules:
                            _vm.employeesGrid.validationOptions.rules
                              .visibility,
                          type: "number"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "visibilityName",
                          headerText: _vm.$t(
                            "calendarTypes.visibility.visibility"
                          ),
                          width: "100"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          width: "90",
                          commands: _vm.employeesGrid.commands
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("h4", { staticStyle: { margin: "12px 0 6px" } }, [
                _vm._v(_vm._s(_vm.$t("rooms.rooms")))
              ]),
              _c(
                "ejs-grid",
                {
                  ref: "calendarTypeRoomsGrid",
                  attrs: {
                    allowSorting: true,
                    dataSource: _vm.calendarType.rooms,
                    editSettings: _vm.roomsGrid.editSettings,
                    sortSettings: _vm.roomsGrid.sortOptions,
                    toolbar: _vm.roomsGrid.toolbar,
                    actionBegin: _vm.roomsGridActionBegin,
                    actionComplete: _vm.roomsGridActionComplete,
                    rowDataBound: _vm.onRoomsGridRowDataBound
                  }
                },
                [
                  _c(
                    "e-columns",
                    [
                      _c("e-column", {
                        attrs: {
                          field: "officeId",
                          width: "0",
                          validationRules:
                            _vm.roomsGrid.validationOptions.rules.officeId,
                          type: "number"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "roomId",
                          headerText: _vm.$t("shared.id"),
                          width: "0",
                          validationRules:
                            _vm.roomsGrid.validationOptions.rules.roomId,
                          type: "number"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "roomName",
                          headerText: _vm.$t("rooms.room"),
                          width: "200"
                        }
                      }),
                      _c("e-column", {
                        attrs: { width: "90", commands: _vm.roomsGrid.commands }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: "shared.edit",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }